import React from 'react';
import NewsContainer from '../components/NewsContainer';


const NewsPage = () => {
    return (
        <NewsContainer />
    );
}

// Export for å kunne importere andre steder aka index.js
export default NewsPage;